import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import "./about.scss"
import About from "./components/about"

const AboutUs = () => (
  <Layout>
    <Seo title="Royal Palm Destinations | Royal Palm Destinations Real Estate " />
    <About/>
  </Layout>
)

export default AboutUs;
